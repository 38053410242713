<template>
  <div>
    <el-card class="container">
      <div class="detail-title">基本信息</div>
      <el-form inline>
        <el-form-item v-for="(item, index) in baseInfo" :key="index" style="width: 40%" :label="item.label">
          <div class="value">
            <template v-if="item.label === '购买人（ID）'">
              {{ orderDetail.memberName }}
              <div v-if="!isThird" class="btn" @click="$router.push(`/user/particulars?id=${orderDetail.memberId}`)">
                查看用户信息>>
              </div>
            </template>

            <template v-else-if="item.label === '订单状态'">
              {{ orderDetail.orderShowStatus | formatOrderStatus }}
            </template>
            <template v-else-if="item.label === '创建时间'">
              {{ new Date(item.value).toLocaleString() }}
            </template>
            <template v-else-if="item.label === '购买方式'">
              {{ item.value === 0 ? '普通' : '特殊' }}
            </template>
            <template v-else-if="item.label === '可用门店'">
              {{ item.value === '' ? '多门店可用' : item.value }}
            </template>

            <template v-else>
              {{ item.value }}
            </template>
          </div>
        </el-form-item>
      </el-form>
      <template v-if="!isThird && !isExpress">
        <div class="detail-title">分销信息</div>
        <el-table :data="distribution.data" class="table">
          <el-table-column
            v-for="col in Object.keys(distribution.label)"
            :key="col"
            :prop="col"
            :label="distribution.label[col]"
          >
            <template slot-scope="{ row }">
              <div v-if="col === 'incomeAmount'">
                <div v-for="(item, index) in row.incomeAmount" :key="'incomeAmount' + index">
                  {{ item }}
                </div>
              </div>
              <div v-else-if="col === 'distributionStatus'">
                <div v-for="(item, index) in row.distributionStatus" :key="'distributionStatus' + index">
                  {{ item }}
                </div>
              </div>
              <div v-else-if="col === 'recordedTime'">
                <div v-for="(item, index) in row.recordedTime" :key="'recordedTime' + index">
                  {{ item }}
                </div>
              </div>
              <template slot-scope="{ row }" v-else>{{ row[col] }}</template>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <div class="detail-title">商品信息</div>
      <el-table :data="productInfo.data" class="table">
        <el-table-column
          v-for="col in Object.keys(productInfo.label)"
          :key="col"
          :prop="col"
          :label="productInfo.label[col]"
        >
          <template slot-scope="{ row }">
            <div class="product" v-if="col === 'product'">
              <img :src="row.product.cover | thumbSmall" />
              <div class="right">
                <div>商品ID：{{ row.product.id }}</div>
                <div class="name">{{ row.product.name }}</div>
              </div>
            </div>
            <template slot-scope="{ row }" v-else-if="col === 'price'">￥{{ row.price }}</template>
            <template slot-scope="{ row }" v-else>{{ row[col] }}</template>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="refundReason" v-if="orderDetail.orderShowStatus === 11"> -->
      <div class="refundReason" v-if="orderDetail.orderRefundList.length > 0 && refundId">
        <div class="detail-title">退款原因</div>
        <div
          class="detil-data"
          v-for="orderRefund in orderDetail.orderRefundList.filter(v => v.id == refundId)"
          :key="orderRefund.id"
        >
          <el-form>
            <el-row>
              <el-col :span="6">
                <el-form-item>
                  <div class="value">{{ orderRefund.refundReasonType }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <div class="value">{{ orderRefund.refundReason }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- <div class="img">
            <img :src="item" alt="" v-for="(item, index) in imgList" :key="index">
          </div> -->
        </div>
      </div>

      <div class="detail-title">金额信息</div>
      <el-form>
        <el-row>
          <el-col :span="6" v-for="key in Object.keys(moneyInfo.label)" :key="key">
            <el-form-item :label="moneyInfo.label[key]">
              <div class="value">￥{{ moneyInfo.value[key] }}</div>
              <p v-if="key === 'totalMoney' && isThird">(贪吃售卖总价)</p>
              <p v-if="key === 'actualPayMoney' && isThird">(串货价*价格浮动比例)</p>
            </el-form-item>
          </el-col>
          <el-col v-if="allActualRefundMoney !== 0 || orderDetail.orderShowStatus === 12" :span="6">
            <el-form-item label="退款总额">
              <div class="value">￥{{ allActualRefundMoney }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template v-if="!isExpress">
        <div class="detail-title">核销码信息</div>
        <div class="checkCodeNum">
          <div class="item">
            <div class="value">{{ checkCodeInfo.data.length }}</div>
            <div class="label">总数</div>
          </div>
          <div class="item" v-for="key in Object.keys(checkCodeInfo.status)" :key="key">
            <div class="value">{{ checkCodeNum[key] }}</div>
            <div class="label">{{ checkCodeInfo.status[key] }}</div>
          </div>
        </div>
        <!-- showRefund -->
        <el-table
          :data="checkCodeInfo.data"
          class="table"
          @selection-change="v => (selected = v)"
          :row-class-name="({ row }) => currentCode.includes(row.checkCode) && 'current'"
        >
          <el-table-column
            type="selection"
            width="55"
            v-if="type === 'detail'"
            :selectable="v => v.showRefund === true"
          >
          </el-table-column>
          <el-table-column align="center" label="核销时间">
            <template slot-scope="{ row }">
              <p>{{ row.checkOffTimeStart }}~{{ row.checkOffTimeEnd }}</p>
            </template>
          </el-table-column>
          <el-table-column
            v-for="col in Object.keys(checkCodeInfo.label)"
            :key="col"
            align="center"
            :prop="col"
            :label="checkCodeInfo.label[col]"
          >
            <template slot-scope="{ row }">
              <div v-if="col === 'checkStatus'">
                <template v-if="row.refundStatus === 0">
                  {{ checkCodeInfo.status[row.checkStatus] }}
                </template>
                <template v-else>
                  {{ row.refundStatus === 1 ? '退款中' : '已退款' }}
                </template>
              </div>
              <div v-else>{{ row[col] }}</div>
            </template>
          </el-table-column>
        </el-table></template
      >

      <div v-if="orderDetail.checkCodeList[0].extraInfoList && orderDetail.checkCodeList[0].extraInfoList.length !== 0">
        <div class="detail-title">购买附带信息</div>
        <div class="skuCard" v-for="(item, index) in orderDetail.checkCodeList" :key="'info' + index">
          <div class="code">核销码: {{ item.checkCode }}</div>
          <div v-for="(childItem, childIndex) in item.extraInfoList" :key="'item' + childIndex" class="item">
            第{{ childIndex + 1 }}份个人信息
            <div
              v-for="(thirdChildItem, thirdChildIndex) in Object.values(childItem)"
              :key="'thirdItem' + thirdChildIndex"
            >
              <div class="name">{{ thirdChildItem.label }} : {{ thirdChildItem.labelValue }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="refundReason" v-show="selected.length > 0">
          <span> 平台退款原因：</span>

          <el-select style="margin-right: 20px" v-model="refundReason" placeholder="请选择">
            <el-option v-for="item in rejectReasonList" :key="item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
          <!-- <el-input placeholder="请输入退款原因" v-model="refundReason"> </el-input> -->
        </div>

        <el-button v-show="selected.length > 0" @click="handleRefund" class="btn" :loading="acting">平台退款</el-button>
      </div>
      <template v-if="type === 'refund'">
        <div class="detail-title">退款处理</div>
        <el-form label-width="150px">
          <template v-if="type === 'refund'">
            <el-form-item label="退款提交时间">
              {{ new Date(refundCode.applyRefundTime).toLocaleString() }}
            </el-form-item>
            <el-form-item label="退款理由">
              {{ refundCode.refundReason }}
            </el-form-item>
          </template>
          <el-form-item label="退款类型">
            <el-radio v-model="refund.type" :label="1">按比例扣退款金额</el-radio>
            <!-- <el-radio v-model="refund.type" :label="2"
              >手动输入退款金额</el-radio
            > -->
          </el-form-item>
          <el-form-item label="扣除用户">
            <div>
              比例 ：<span style="margin-right: 20px; color: red; font-weight: bold; font-size: 18px">
                {{ refund.input }}%</span
              >
              金额： <span style="color: red; font-weight: bold; font-size: 18px"> {{ refund.deduct }}元</span>
              <span class="tip" style="margin-left: 20px"
                >此处扣除比例默认为商品设置的退款比例{{ refund.input }}%，管理员可在平台设置更改退款比例</span
              >
            </div>
          </el-form-item>
          <el-form-item label="申请退款核销码" v-if="type === 'refund'">
            <span v-for="item in refundCode.orderRefundItemList" :key="item.orderCheckCodeId"
              >{{ item.orderCheckCode }}
            </span>
          </el-form-item>
          <el-form-item label="退款金额"
            >￥{{ totalPay - refund.deduct >= 0 ? (totalPay - refund.deduct).toFixed(2) : 0 }}</el-form-item
          >
          <el-form-item label="退款方式">{{
            type === 'detail' || refundCode.refundWay === 1 ? '原路退回' : '线下退款'
          }}</el-form-item>
          <el-form-item label="拒绝原因" v-if="type === 'refund'">
            <el-select v-model="rejectReason.selected" placeholder="请选择">
              <el-option v-for="item in rejectReason.list" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="tip" style="margin-left: 20px">选择其中一个退款原因，若批量拒绝操作则默认第一条</span>
          </el-form-item>
        </el-form>

        <div class="action">
          <el-button class="btn" :loading="acting" @click="handleClick(true)">同意</el-button>
          <el-button class="btn outline-btn" :loading="acting" @click="handleClick(false)">拒绝</el-button>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'detail'
    },
    id: {
      type: String,
      default: ''
    },
    isThird: {
      type: Boolean,
      default: false
    },
    isExpress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgList: [
        'https://file.cdn.tanchi.shop/dev/1/commodity/a4f8e340737fbedc91b256ce407b4d4f?x-oss-process=style/thumb_200',
        'https://file.cdn.tanchi.shop/dev/1/commodity/a4f8e340737fbedc91b256ce407b4d4f?x-oss-process=style/thumb_200',
        'https://file.cdn.tanchi.shop/dev/1/commodity/a4f8e340737fbedc91b256ce407b4d4f?x-oss-process=style/thumb_200',
        'https://file.cdn.tanchi.shop/dev/1/commodity/a4f8e340737fbedc91b256ce407b4d4f?x-oss-process=style/thumb_200'
      ], // 测试的图片列表
      orderDetail: {
        orderRefund: {},
        checkCodeList: [],
        orderRefundItemList: [],
        orderRefundList: []
      },
      //基本信息
      baseInfo: {
        id: {
          label: '订单号',
          value: ''
        },
        memberId: {
          label: '购买人（ID）',
          value: ''
        },
        orderStatus: {
          label: '订单状态',
          value: '待消费'
        },
        mobile: {
          label: '购买人电话',
          value: ''
        },
        buyType: {
          label: '购买方式',
          value: '普通'
        },
        notes: {
          label: '订单备注',
          value: ''
        },

        createTime: {
          label: '创建时间',
          value: ''
        },
        storeName: {
          label: '可用门店',
          value: ''
        }
      },

      distribution: {
        label: {
          distributionSource: '收益类型',
          distributionMemberNickname: '分销用户',
          incomeAmount: '分销佣金',
          distributionStatus: '分销状态',
          recordedTime: '入账时间'
        },
        data: []
      },
      //商品信息

      productInfo: {
        label: {
          product: '商品信息',
          businessName: '商家',
          buyType: '购买方式',
          skuName: '款式',
          price: this.isThird ? '款式单价(贪吃售卖价)' : '款式单价',
          skuTotalNum: '数量'
        },
        data: []
      },
      //金额信息
      moneyInfo: {
        label: {
          totalMoney: '订单总额',
          totalDiscountMoney: '优惠金额',
          actualPayMoney: '实付金额'
        },
        value: {
          totalMoney: 352,
          totalDiscountMoney: 10,
          actualPayMoney: 342
        }
      },
      //核销码信息
      checkCodeInfo: {
        status: {
          1: '未核销',
          2: '已核销',
          3: '已过期',
          4: '退款中',
          5: '已退款'
        },
        label: { id: '子订单号', checkCode: '核销码', checkStatus: '状态', time: '核销码信息' },
        data: []
      },
      allActualRefundMoney: 0,
      refund: {
        type: 1, //退款类型
        input: 2, //退款比例
        deduct: 0 //退款金额
      },
      refundReason: '',
      rejectReasonList: [],
      rejectReason: {
        selected: 1,
        list: [
          {
            label: '此商品不能退款',
            value: 1
          },
          {
            label: '此商品已经核销',
            value: 2
          },
          {
            label: '微信故障，暂不可退款',
            value: 3
          }
        ]
      },
      selected: [], //选择要退款的核销码-detail
      acting: false
    }
  },
  computed: {
    checkCodeNum() {
      // 统计核销码各类型数量
      let num = {}
      Object.keys(this.checkCodeInfo.status).map(item => (num[item] = 0))
      this.checkCodeInfo.data.map(
        item => num[item.refundStatus !== 0 ? (item.refundStatus === 1 ? 4 : 5) : item.checkStatus]++
      )
      return num
    },
    refundCode() {
      if (this.orderDetail.orderRefundList.length === 0) return {}
      for (let item of this.orderDetail.orderRefundList) {
        if (item.id === this.$route.query.refundId) {
          return item
        }
      }
    },
    refundId() {
      return this.$route.query.refundId
    },
    currentCode() {
      if (!this.refundCode || !this.refundCode.orderRefundItemList) return []
      return this.refundCode.orderRefundItemList.map(item => item.orderCheckCode)
    },
    totalPay() {
      if (this.type === 'detail') return this.orderDetail.actualPayMoney
      let total = 0
      this.orderDetail.checkCodeList.map(item => {
        if (this.currentCode.includes(item.checkCode)) {
          total += item.actualPayMoney
        }
      })
      return total
    }
  },
  watch: {
    'refund.input'(v) {
      this.refund.deduct = this.refund.type === 1 ? Math.floor(this.totalPay * (v / 100) * 100) / 100 : v
    },
    'refund.type'(v) {
      this.refund.input = 0
      this.refund.deduct = 0
      if (v === 1) {
        this.refund.input = 2
        this.refund.deduct = Math.floor(this.totalPay * this.refund.input) / 100
      }
    }
  },
  methods: {
    gotoDetail(id) {
      this.$router.push({
        path: '/order/express/detail',
        query: {
          id: id
        }
      })
    },
    // 退款原因列表
    getRefundReasonList() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReason/list')
        .then(res => {
          this.rejectReasonList = res
          this.refundReason = res[0].name
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleCheckCode(value, refundList) {
      if (value.refundStatus === 1) {
        let applyRefundTime
        refundList.forEach(item => {
          if (value.checkCode === item.orderCheckCode) {
            applyRefundTime = this.formatTime(item.applyRefundTime)
          }
        })

        return `退款发起时间 :${applyRefundTime} `
      } else if (value.refundStatus === 2) {
        let applyRefundTime
        let realityRefundTime
        let actualRefundMoney
        refundList.forEach(item => {
          if (value.checkCode === item.orderCheckCode) {
            applyRefundTime = this.formatTime(item.applyRefundTime)
            realityRefundTime = this.formatTime(item.realityRefundTime)
            actualRefundMoney = item.actualRefundMoney
          }
        })

        return `退款金额 ：${actualRefundMoney} 退款发起时间:
         ${applyRefundTime}
       退款时间：${realityRefundTime}`
      } else {
        let checkTime = this.formatTime(value.checkTime)

        if (value.checkStatus === 2) {
          return `核销时间：${checkTime}| 核销人：${
            value.checkBusinessUserName ? value.checkBusinessUserName : '自助核销'
          }|  核销门店：${value.checkStoreName}`
        }
      }
    },
    getDetail() {
      this.$http
        .get('boom-center-order-service/sysAdmin/order/' + this.$route.query.id, {
          params: {
            memberId: this.$route.query.memberId
          }
        })
        .then(res => {
          this.getOrderDistribution(res.id)
          this.orderDetail = res
          this.refund.deduct = Math.floor(res.totalMoney * this.refund.input) / 100
          Object.keys(res).map(key => {
            if (this.baseInfo[key]) {
              this.baseInfo[key].value = res[key]
            }
            if (this.moneyInfo.value[key]) {
              this.moneyInfo.value[key] = res[key]
            }
          })
          res.orderItemList.map(item => {
            this.productInfo.data.push({
              product: {
                cover: item.skuPic,
                id: item.productId,
                name: item.productName
              },
              businessName: item.businessName,
              buyType: res.buyType === 0 ? '普通' : '特殊',
              skuName: item.skuName,
              price: (item.skuTotalMoney / item.skuTotalNum).toFixed(2),
              skuTotalNum: item.skuTotalNum
            })
          })

          res.orderRefundList.forEach(item => {
            this.allActualRefundMoney = +(item.actualRefundMoney + this.allActualRefundMoney).toFixed(2)
          })

          res.checkCodeList.map(item => {
            this.checkCodeInfo.data.push({
              checkOffTimeStart: this.formatTime(item.checkOffTimeStart),
              checkOffTimeEnd: this.formatTime(item.checkOffTimeEnd),
              checkCode: item.checkCode,
              checkStatus: item.checkStatus,
              refundStatus: item.refundStatus,
              id: item.id,
              showRefund: item.showRefund,
              time: this.handleCheckCode(item, res.orderRefundList)
            })
          })
          this.$log(this.checkCodeInfo.data)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleClick(act) {
      跳转活动或链接必填(act)
      let params = {
        deductMoney: this.refund.deduct,
        deductionRatio: parseFloat((this.refund.input / 100).toFixed(2)),
        denialNotes: this.rejectReason.list[this.rejectReason.selected].label,
        orderRefundId: this.refundCode.id,
        orderId: this.orderDetail.id,
        refundStatus: this.refund.type,
        refundSysUserId: 1,
        refundSysUserName: 'uesrname',
        verifyStatus: act ? 1 : 2,
        memberId: this.$route.query.memberId
      }

      this.acting = true
      this.$http
        .post('boom-center-order-service/sysAdmin/refund/audit', params)
        .then(res => {
          const msg = params.verifyStatus === 1 ? '已同意退款' : '已拒绝退款'
          this.$message.success(msg)
          this.$emit('refresh')
          this.$router.back()
        })
        .catch(err => {
          this.acting = false
          this.$message.error(err.msg || '操作失败')
        })
    },
    formatTime(value) {
      if (value === null || value === '' || value === undefined) {
        return '----'
      }
      const date = new Date(parseInt(value))
      const year = date.getFullYear()
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

      return `${year}-${month}-${day} ${hh}:${mm}:${ss}`
    },
    handleInputChange(v) {
      if (this.refund.type === 2) {
        this.refund.input = Math.floor(v * 100) / 100
      }
    },
    handleRefund() {
      let checkCodeList = []
      this.selected.forEach(item => {
        let obj = {}
        obj.orderCheckCodeId = item.id
        checkCodeList.push(obj)
      })

      let params = {
        checkCodeList: checkCodeList,
        memberId: this.$route.query.memberId,
        // 会员ID
        orderId: this.orderDetail.id,
        // 订单ID
        refundAmount: this.productInfo.data[0].price * checkCodeList.length,
        // 退款金额
        refundReason: this.refundReason
      }
      this.$http
        .post('boom-center-order-service/sysAdmin/refund/apply', params)
        .then(res => {
          this.$message.success('平台退款成功，请到退款列表操作')
          this.$sleep(2.5).then(res => {
            this.$router.push({
              path: '/order/list'
            })
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    filter(val) {
      if (val === 0) {
        return '待入账'
      } else if (val === 1) {
        return '已入账'
      } else {
        return '已失效'
      }
    },
    getOrderDistribution(id) {
      this.$http.get('/boom-center-user-service/sysAdmin/distribution/bill/order/' + id).then(res => {
        let obj1 = {
          distributionMemberId: '',
          distributionSource: 0,
          distributionMemberNickname: '',
          incomeAmount: [],
          distributionStatus: [],
          recordedTime: []
        }
        let obj2 = {
          distributionMemberId: '',
          distributionSource: 0,
          distributionMemberNickname: '',
          incomeAmount: [],
          distributionStatus: [],
          recordedTime: []
        }
        let boo = false
        let boo1 = false

        res.forEach(item => {
          if (item.distributionSource === 0) {
            boo = true
            obj1.distributionMemberId = item.distributionMemberId
            obj1.distributionSource = '直接收益'
            obj1.distributionMemberNickname = item.distributionMemberNickname
            obj1.incomeAmount.push(item.incomeAmount)
            obj1.distributionStatus.push(this.filter(item.distributionStatus))
            obj1.recordedTime.push(item.recordedTime)
          } else {
            boo1 = true
            obj2.distributionMemberId = item.distributionMemberId
            obj2.distributionSource = '团队收益'
            obj2.distributionMemberNickname = item.distributionMemberNickname
            obj2.incomeAmount.push(item.incomeAmount)
            obj2.distributionStatus.push(this.filter(item.distributionStatus))
            obj2.recordedTime.push(item.recordedTime)
          }
        })
        if (res.length !== 0) {
          if (boo) {
            this.distribution.data.push(obj1)
          }
          if (boo1) {
            this.distribution.data.push(obj2)
          }
        }

        boo = null
        boo1 = null
      })
    }
  },
  created() {
    if (this.$route.query.productType == 3) {
      this.checkCodeInfo.label = {
        id: '子订单号',
        checkStatus: '状态',
        time: '核销码信息'
      }
    } else {
      this.checkCodeInfo.label = {
        id: '子订单号',
        checkCode: '核销码',
        checkStatus: '状态',
        time: '核销码信息'
      }
    }
    if (this.type === 'refund') {
      this.$http
        .get('boom-center-admin-service/sysAdmin/mallSetting')
        .then(res => {
          this.refund.input = res.chargeProportion
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
    this.getRefundReasonList()
    this.getDetail()
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: @container-margin;
  padding: 20px;

  .detail-title {
    margin-bottom: 10px;
  }
  .refundReason .img img {
    width: 150px;
    height: 150px;
    margin-right: 10px;
  }

  .value {
    font-weight: bold;

    .btn {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #e7141a;
      margin-left: 15px;
    }
  }

  .table {
    margin-bottom: 25px;

    .product {
      display: flex;
      align-items: center;

      img {
        border-radius: 5px;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }

      .right {
        flex: 1;
        overflow: hidden;
        margin-left: 10px;

        .name {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .checkCodeNum {
    margin-bottom: 30px;

    .item {
      widows: 100px;
      text-align: center;
      display: inline-block;
      margin-right: 30px;

      .value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
      }

      .label {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .tip {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 20px;
  }

  .input {
    width: 150px;
  }

  .action {
    margin-top: 50px;
    .refundReason {
      margin: 20px;
      .el-input {
        width: 250px;
      }
    }

    .btn {
      border-radius: 30px;
      background: @color-primary-select;
      color: #ffffff;
      padding: 15px 66px;
      margin-right: 20px;
      border: 1px solid @color-primary-select;
      font-size: 15px;
    }

    .outline-btn {
      background: #ffffff;
      color: @color-primary-select;
    }
  }
}
.deduct {
  display: inline-block;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 20px;
}
/deep/.current {
  background: rgba(255, 0, 0, 0.05);
}

/deep/.el-form-item__label {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: left;
}
.skuCard {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background: var(--background);
  width: 500px;
  padding: 10px;
  margin-top: 20px;
  .code {
    font-weight: bold;
  }
  .item {
    margin-top: 16px;
    .name {
      margin-top: 8px;
    }
  }
}
</style>
